<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        <b>{{
          inputs.language === 'en' ? 'Select the best answer' : 'Sélectionnez la meilleure réponse'
        }}</b
        >.
        {{
          inputs.language === 'en'
            ? 'Why should the separatory funnel be shaken vigorously?'
            : "Pourquoi faut-il secouer vigoureusement l'ampoule à décanter?"
        }}
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <v-btn-toggle
        v-model="inputs.language"
        class="ml-2"
        size="small"
        dense="dense"
        rounded="rounded"
        mandatory
      >
        <v-btn :value="'en'">English</v-btn>
        <v-btn :value="'fr'">Français</v-btn>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab3Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'To make sure the chemicals react', value: 'react'},
        {text: 'Mass transfer between two immiscible phases is required', value: 'transfer'},
        {text: 'To facilitate the evaporation of solvents', value: 'evaporation'},
        {text: 'To make sure all the chemicals are fully dissolved', value: 'dissolved'},
      ],
      optionsFr: [
        {text: 'Pour être certain que les produits chimiques réagissent', value: 'reactFr'},
        {
          text: 'Un transfert de masse entre deux phrases immiscibles est nécessaire',
          value: 'transferFr',
        },
        {text: "Pour faciliter l'évaporation des solvants", value: 'evaporationFr'},
        {
          text: 'Pour être certain que les produits chimiques sont complètement dissous',
          value: 'dissolvedFr',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
